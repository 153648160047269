<template>
  <div>
    <!-- <h3>Zeiteinträge</h3>
    <TimeRecords />-->
    <h3>Kurs-Zeiteinträge</h3>
    <table class="table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th class="text-center">#</th>
          <th>Datum</th>
          <th>Studio</th>
          <th>Kurs</th>
          <th>Teilnehmer</th>
          <th>Std</th>
          <th>Stundenlohn (€)</th>
          <th class="text-center">Bearbeiten</th>
          <th class="text-center">Löschen</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="9">
            <router-link :to="{ name: 'course-time-record-create' }">+ Zeiteintrag erstellen</router-link>
          </td>
        </tr>
        <tr>
          <td colspan="9">
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="Zeiteintrag suchen..."
              v-model.trim="search"
            />
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <input type="month" class="form-control form-control-sm" v-model.trim="selectedDate" />
          </td>
          <td colspan="5">
            <select v-model="selectedHolding" class="form-control form-control-sm">
              <option :value="''">Alle Holdings</option>
              <option v-for="holding in possibleHoldings" :key="holding">{{holding}}</option>
            </select>
          </td>
        </tr>
        <tr v-if="selectedDate">
          <td colspan="9">Lohn für den ausgewählten Zeitraum (Ohne Ust.): {{calculatedWageBr}}</td>
        </tr>
        <tr v-if="selectedDate && hasVatKey">
          <td colspan="9">Lohn für den ausgewählten Zeitraum (Mit Ust.): {{calculatedWage}}</td>
        </tr>
        <tr v-for="(courseTimeRecord, index) in filteredRecords" :key="courseTimeRecord.id">
          <th class="text-center">{{ index + 1 }}</th>
          <td>
            {{ courseTimeRecord.date | date('dd.MM.yyyy') }}
            {{
            courseTimeRecord.course.starts +
            '-' +
            courseTimeRecord.course.ends
            }}
          </td>
          <td>{{ courseTimeRecord.studio.name }}</td>
          <td>{{ courseTimeRecord.course.name }}</td>
          <td>{{ courseTimeRecord.participants }}</td>
          <td>{{ parseFloat(courseTimeRecord.time / 60).toFixed(2) }}</td>
          <td>{{ courseTimeRecord.trainer.hourlyWage}}</td>
          <td class="text-center">
            <router-link
              :to="{
                name: 'course-time-record-details',
                params: { id: courseTimeRecord.id },
              }"
            >
              <i class="fa fa-edit text-dark"></i>
            </router-link>
          </td>
          <td class="text-center" @click="remove(courseTimeRecord.id)">
            <i class="fa fa-trash cursor-pointer"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { format, isWithinInterval, parseISO } from 'date-fns';
import { uniqWith, isEqual, groupBy } from 'lodash';

import { firestore } from '../firebase';

export default {
  data() {
    return {
      loading: false,
      selectedHolding: '',
      courseTimeRecords: [],
      search: '',
      selectedDate: '',
      hasVatKey: false,
    };
  },
  async created() {
    firestore
      .collection('courseTimeRecords')
      .where('trainer.id', '==', this.$route.params.trainerId)
      .orderBy('date', 'desc')
      .orderBy('course.starts', 'desc')
      .onSnapshot((snapshot) => {
        this.courseTimeRecords = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        this.hasVatKey = this.filteredRecords.some(
          (record) => record.trainer.vatkey === 9
        );
      });
  },
  methods: {
    async remove(id) {
      if (
        confirm('Bist du sicher, dass du diesen Zeiteintrag löschen möchtest?')
      ) {
        await firestore.collection('courseTimeRecords').doc(id).delete();

        this.courseTimeRecords = this.courseTimeRecords.filter(
          (courseTimeRecord) => courseTimeRecord.id !== id
        );
      }
    },
  },
  computed: {
    possibleHoldings() {
      const groupedByHoldings = groupBy(
        this.courseTimeRecords,
        'studio.holding'
      );

      return Object.keys(groupedByHoldings);
    },
    filteredRecords() {
      return this.courseTimeRecords.filter((record) => {
        return (
          record.date.includes(this.selectedDate) &&
          record.studio.holding.includes(this.selectedHolding) &&
          (record.studio.name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
            record.studio.holding
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            record.course.name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            format(new Date(record.date), 'dd.MM.yyyy').includes(
              this.search.toLowerCase()
            ))
        );
      });
    },
    possibleMonthsWithYear() {
      return uniqWith(
        this.courseTimeRecords.map((record) => {
          return {
            year: record.date.split('-')[0],
            month: record.date.split('-')[1],
          };
        }),
        isEqual
      );
    },
    calculatedWageBr() {
      if (this.selectedDate) {
        let tempAmount = this.filteredRecords.reduce((prev, curr) => {
          return (
            prev +
            curr.time *
              parseFloat((curr.trainer.hourlyWage / 60).toPrecision(12))
          );
        }, 0.0);

        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(tempAmount);
      } else {
        return '';
      }
    },
    calculatedWage() {
      if (this.selectedDate && this.hasVatKey) {
        let vatKey = false;
        let vatPercent = 0.19;

        if (
          isWithinInterval(parseISO(this.selectedDate), {
            start: new Date(2020, 6, 1),
            end: new Date(2020, 11, 31),
          })
        ) {
          vatPercent = 0.16;
        }

        if (
          this.filteredRecords.some((record) => record.trainer.vatkey === 9)
        ) {
          vatKey = true;
        }

        let tempAmount = this.filteredRecords.reduce((prev, curr) => {
          return (
            prev +
            curr.time *
              parseFloat((curr.trainer.hourlyWage / 60).toPrecision(12))
          );
        }, 0.0);

        if (vatKey) {
          tempAmount =
            parseFloat(tempAmount) + parseFloat(tempAmount) * vatPercent;
        }

        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(tempAmount);
      } else {
        return '';
      }
    },
  },
};
</script>
